import React from 'react'
import '../Style/style.css'
import RoadmapImage from '../Assets/rt-journey-img-min.png'

export default function Roadmap() {
    return (
        <>
        <section className="roadmap" id="roadmap">
			<div className="container border-btm">
				<div className="roadmap-content-wrapper">
					<div className="roadmap-img">
						<img src={RoadmapImage} alt="RoadmapImage" />
					</div>
					<div className="roadmap-detail">
						<h1>Roadmap</h1>
						<div className="roadmap-content">
							<p>
								The Rockin Tuna team is committed to making the project a wild
								success. We’ve setup goals that will be completed once we hit each
								percentage.
							</p>
						</div>
						<div className="roadmap-information">
							<h2>Community Pre-Sale</h2>
							<p>
								<a href="https://discord.gg/jN5dXEzEvh" className="link"> Join our community </a>to get
								Whitelisted and start minting before the public sale.
							</p>
							<h2>Public Sale</h2>
							<p>The first 300 NFTs price is 0.03 ETH. Then mint your Rockin Tuna for 0.05 ETH</p>
							<h2>10% Sold</h2>
							<p>
								10 rare NFT giveaways to our Discord members and Twitter
								followers.
							</p>
							<h2>20% Sold</h2>
							<p>
								20 secret cloned Tunas (tokens held back from the sale) are
								saved to be airdropped to random NFT holders.
							</p>
							<h2>50% Sold</h2>
							<p>
								Exclusive Merch store gets unlocked that include t-shirts,
								hoodies, hats and more. Merch Giveaways. You will have a chance
								to vote on final designs.
							</p>
							<h2>80% Sold</h2>
							<p>We will allocate more funds to continue to run paid marketing promotions to the Rockin Tuna Collection.</p>
							<h2>90% Sold</h2>
							<p>ETH raffle to Rockin Tuna NFT holders.</p>
							<h2>100% Sold</h2>
							<p>
								The new Collection Begins! <br />
								We plan to also give away 100 of this collection to the NFT
								holders and discord community members. This exclusive collection
								will consist of 1,000 and available to Rockin Tuna holders before the
								public sale.
							</p>
							<div className="roadmap-button">
								<a href="https://opensea.io/collection/rockintuna" target="_blank">Buy in OpenSea</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        </>
    )
}
