import React, {useEffect} from 'react';
import About from "./Sections/About";
import Artist from "./Sections/Artist";
import Footer from "./Sections/Footer";
import Header from "./Sections/Header";
import Launch from "./Sections/Launch";
import Legends from "./Sections/Legends";
import Roadmap from "./Sections/Roadmap";
import Team from "./Sections/Team";
import { ToastContainer } from 'react-toastify';
import web3 from './Store/web3'

function App() {

  useEffect(() => {
    web3.loadSaleInfo()
    web3.connectMetamask()
  }, [])

  return (
    <>
    <Header/>
    <About/>
    <Launch/>
    <Roadmap/>
    <Legends/>
    <Artist/>
    <Team/>
    <Footer/>
    <ToastContainer/>
    </>
  );
}

export default App;
