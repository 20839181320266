import React, {useState} from 'react'
import '../Style/style.css'
import HeaderLogo from '../Assets/rt-header-logo1.png'
import HeaderLogo2 from '../Assets/rt-header-logo2.png'
import HeaderBackground from '../Assets/rockintuna-header-bg-transparent.png'
import HeaderBackgroundMobile from '../Assets/RockinTuna-BG-m-2x.jpg'
import HeaderImageMain from '../Assets/rt-top-img.png'
import Discord from '../Assets/discord.png'
import Twitter from '../Assets/twitter.png'
import Instagram from '../Assets/instagram.png'
import OpenSea from '../Assets/opensea.png'

import web3 from './../Store/web3'
import {observer} from 'mobx-react-lite'



export default function Header() {
    const [Navbaractive, setNavbaractive] = useState(false);
    return (
    <>
    <header>
        <nav className="header-navigation">
            <div className="hamburger" onClick={() => setNavbaractive(!Navbaractive)}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
            <div className="logo">
                <img src={HeaderLogo} alt="HeaderLogo"/>
            </div>
            <ul className={Navbaractive ? "navigation active" : "navigation"}>
                <div className="close">
                    <div className="hamburger" onClick={() => setNavbaractive(!Navbaractive)}>
                        <div className="close1"></div>
                        <div className="close2"></div>
                        <div className="close3"></div>
                    </div>
                </div>
                <div className="logo-mobile">
                    <img src={HeaderLogo} alt="HeaderLogo"/>
                </div>
                <li><a href="#about">ABOUT</a></li>
                <li><a href="#roadmap">ROADMAP</a></li>
                <li><a href="#artist">ARTIST</a></li>
                <li><a href="#legends">LEGENDS</a></li>
                <li><a href="#team">TEAM</a></li>
                <li><a href="#launch">MINT</a></li>
                <ConnectWallet isMobile={true}/>
            </ul>
            <ul className="social-icons">
                <li><a href="https://discord.gg/jN5dXEzEvh" target="_blank"><img src={Discord} alt="Discord"/></a></li>
                <li><a href="https://twitter.com/rockintuna" target="_blank"><img src={Twitter} alt="Twitter"/></a></li>
                <li><a href="https://www.instagram.com/rockintunanft/" target="_blank"><img src={Instagram} alt="Instagram"/></a></li>
                <li><a href="https://opensea.io/collection/rockintuna" target="_blank"><img src={OpenSea} alt="OpenSea"/></a></li>
                <ConnectWallet isMobile={false}/>
            </ul>
        </nav>
    </header>
    <div className="hero-section">
        <div className="back-img">
            <img src={HeaderBackground} alt="HeaderBackground" className="bg-header-dsk"/>
            <img src={HeaderBackgroundMobile} alt="HeaderBackgroundMobile" className="bg-header-mob"/>
        </div>
        <div className="hero-container">
            <div className="hero-content-wrapper">
                <div className="hero-content">
                    <div className="hero-logo">
                        <img src={HeaderLogo2} alt="HeaderLogo2"/>
                    </div>
                    <p>Rockin Tuna is a collection of hand drawn NFTs avatars by world renowned artist Tony Ciavarro living on the Ethereum Blockchain</p>
                    <div className="hero-buttons">
                        <a href="https://discord.gg/jN5dXEzEvh" target="_blank">Join us on Discord</a>
                        <a href="https://opensea.io/collection/rockintuna" target="_blank">Visit Collection</a>
                    </div>
                </div>
                <div className="hero-image">
                    <img src={HeaderImageMain} alt="HeaderImageMain"/>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

const ConnectWallet = observer(({isMobile}) => {
  return (
    <li>
      <a onClick={_ => web3.connectMetamask()} className={isMobile ? "wallet-btn-mobile" : "wallet-btn"}>
        {web3.wallet ? (web3.wallet.substring(0, 12) + '...') : 'Connect Wallet'}
      </a>
    </li>
  )
})
