import React from 'react'
import '../Style/style.css'

import web3 from './../Store/web3'
import {observer} from 'mobx-react-lite'

export default function Launch() {
    return (
        <>
        <section className="launch" id="launch">
			<div className="container border-btm">
				<div className="launch-content-wrapper">
					<h1>MINTING IS CLOSED</h1>
					<div className="lauch-information">
						<div className="sales-info">
							<div className="sale mb">
								<h2>Price</h2>
								<p>Mint price is 0.03 ETH</p>
								<small>Buy in OpenSea Marketplace</small>
							</div>
							<div className="sale">
								<h2>Sale</h2>
								<p>Sold Out</p>
	   							<small>Buy in OpenSea Marketplace</small>
							</div>
						</div>
						<div className="key-info">
							<div className="key-info-content">
								<h2>Key Information</h2>
								<p>Total supply: 300</p>
								<p>Max Limited Per Transaction: 10</p>
								<p>Blockchain: Ethereum</p>
	    							<p>Marketplace: OpenSea</p>
							</div>
						</div>
					</div>
					<div className="mint-rockin-tuna">
						<h2>Mint how many Rockin Tuna?</h2>
            <Mint/>
						<p>
							BUY A ROCKIN TUNA IN OPENSEA MARKETPLACE
						</p>
	    					<div className="hero-buttons">
						<a href="https://opensea.io/collection/rockintuna" target="_blank">VISIT COLLECTION</a>
					</div>
					</div>
				</div>
			</div>
		</section>
        </>
    )
}


const Mint = observer(_ => {
  return (
    <div className="mint-container">
      <div className="toggle">
        <input type="button" value="-" className="toggle-minus" onClick={_ => web3.amountToMintDecrease()}/>
        <div className="toggle-amount">{web3.amountToMint}</div>
        <input type="button" value="+" className="toggle-plus" onClick={_ => web3.amountToMintIncrease()}/>
      </div>
      <input type="button" value="Mint" className="mint-button" onClick={_ => web3.mint()}/>
    </div>
  )
})
