import { toast as _toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toast = title => {
  return new Promise(async resolve => {
    await _toast.dark(title, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    resolve()
  })
}

export const toastSuccess = title => {
  return new Promise(async resolve => {
    await _toast.success(title, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    resolve()
  })
}
