import React from 'react'
import '../Style/style.css'
import ArtistImage from '../Assets/rt-tony-img-min.png'
import ArtistSignature from '../Assets/rt-tony-signature.png'
import WaveSeperator2 from '../Assets/rockintuna-separator2.png'
import ArtistBackground from '../Assets/rt-artist-bg.jpg'
import ArtistBackgroundMob from '../Assets/RockinTuna-ArtistBG-m-2x.jpg'
import ArtistLogo1 from '../Assets/rt-tony-logo-stinkymonkey.png'
import ArtistLogo2 from '../Assets/rt-tony-logo-slowdown.png'

export default function Artist() {
    return (
        <>
        <section className="artist" id="artist">
			<div className="separator">
				<img src={WaveSeperator2} alt="WaveSeperator2" />
			</div>
			<div className="artist-bg">
				<img src={ArtistBackground} alt="ArtistBackground" className="bg-header-dsk" />
				<img
					src={ArtistBackgroundMob}
					alt="ArtistBackgroundMob"
					className="bg-header-mob"
				/>
				<div className="artist-container">
					<div className="artist-content-wrapper">
						<div className="container flex">
							<div className="artist-content">
								<div className="artist-content-img">
									<span>THE ARTIST</span>
									<img src={ArtistSignature} alt="ArtistSignature" />
								</div>
								<p>
									Tony has been in and around the tattoo industry for 25 years.
									Starting in 1997 drawing tattoo flash, he started going to
									conventions all over the world to spread his art to as many
									tattoo studios as possible. He is one of the early creators of
									a style in tattooing called New School. The style is
									recognized for its imagination and bold, bright coloring. Tony
									has numerous books that have been self published to this
									point. He has decided to share his art and make it accessible
									to people all over the globe.
								</p>
								<div className="artist-logos">
									<img src={ArtistLogo1} alt="ArtistLogo1" />
									<img src={ArtistLogo2} alt="ArtistLogo2" />
								</div>
							</div>
							<div className="artist-img">
								<img src={ArtistImage} alt="ArtistImage" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        </>
    )
}
