import React from 'react'
import '../Style/style.css'
import LegendsImage from '../Assets/rt-legends.png'

export default function Legends() {
    return (
        <>
        <section className="legend" id="legends">
			<div className="container">
				<div className="legend-content-wrapper">
					<div className="legend-content">
						<h1>Legends</h1>
						<p>
							Legends by Rockin Tuna are the rarest NFTs. They are completely
							hand drawn with ultra-unique variants. Each legend will represent
							a music genre. These will be randomly distributed during the
							public mint and reveal.
						</p>
					</div>
					<div className="legend-image">
						<img src={LegendsImage} alt="LegendsImage" />
					</div>
				</div>
			</div>
		</section>
        </>
    )
}
