import {makeAutoObservable} from 'mobx'
import Web3 from 'web3'
import {toast, toastSuccess} from './../Helpers/toast'

import { abi } from './../web3data/abi';
import { address } from './../web3data/address';
import { infura } from './../web3data/infura';


class Web3Connection {

  totalSupply = 0
  amountToMint = 1
  saleStatus = 0
  currentPrice = false
  wallet = false

  constructor() {
    makeAutoObservable(this)
  }

  amountToMintIncrease() {
    if(this.amountToMint === 10) return
    else this.amountToMint = this.amountToMint + 1
  }

  amountToMintDecrease() {
    if(this.amountToMint === 1) return
    else this.amountToMint = this.amountToMint - 1
  }



  async loadSaleInfo() {
    try { 
      const web3 = new Web3(infura);
      const Contract = new web3.eth.Contract(abi, address);
      const _totalSupply = await Contract.methods.totalSupply().call();
      const _saleStatus = await Contract.methods.saleStatus().call();
      const _currentPrice = await Contract.methods.currentPrice().call();
      this.totalSupply = Number(_totalSupply);
      this.saleStatus = _saleStatus;
      this.currentPrice = _currentPrice;
    }
    catch {
    }
  }

  async connectMetamask() {
    try { 
      if (typeof window.ethereum !== 'undefined') {}
      else return toast('Please install Metamask')
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      this.wallet = account
    }
    catch{
      toast('Metamask is not connected')
    }
  }

  async mint() {
    try {
        
      return toast ('Minting Closed. Buy in OpenSea Marketplace')
      
      const amountToMint = Number(this.amountToMint)
      if (typeof window.ethereum !== 'undefined') {}
      else return toast('Please install Metamask')
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      this.wallet = account
      const web3 = new Web3(window.ethereum);
      const Contract = new web3.eth.Contract(abi, address);

      if(this.totalSupply == 3000) return toast('All NFTs are minted')
      if(this.saleStatus == 0) return toast('Sale is not started')

      await Contract.methods.mint(amountToMint).send({
        from: account,
        value: this.currentPrice * amountToMint
      })

      toastSuccess('Congratulations!')
      this.totalSupply = this.totalSupply + amountToMint


    }
    catch(e) {
      toast(e.message)
    }
  }
}

export default new Web3Connection()
