import React from 'react'
import '../Style/style.css'
import FooterLogo from '../Assets/rt-header-logo1.png'
import Discord from '../Assets/discord.png'
import Twitter from '../Assets/twitter.png'
import Instagram from '../Assets/instagram.png'
import OpenSea from '../Assets/opensea.png'

export default function Footer() {
    return (
        <>
        <div className="container">
            <footer>
            <div className="footer-logo">
                <img src={FooterLogo} alt="FooterLogo"/>
            </div>
            <ul className="footer-nav">
                <li><h3>About</h3></li>
                <li><a href="#team">Team</a></li>
                <li><a href="#artist">Artist</a></li>
                <li><a href="#launch">Mint</a></li>
            </ul>
            <ul className="footer-nav">
                <li><h3>Support</h3></li>
                <li><a href="https://greenslant.com/rockin-tuna-faqs/" target="_blank">FAQ's</a></li>
                <li><a href="#roadmap">Roadmap</a></li>
                <li><a href="https://greenslant.com/rockin-tuna-contact/" target="_blank">Contact</a></li>
            </ul>
            <ul className="footer-nav">
                <li><h3>Legal</h3></li>
                <li><a href="https://greenslant.com/rockin-tuna-privacy-policy/" target="_blank">Privacy Policy</a></li>
                <li><a href="https://greenslant.com/terms-of-use-rockin-tuna/" target="_blank">Terms of Use</a></li>
            </ul>
            <ul className="footer-social-icons">
                <li><a href="https://discord.gg/jN5dXEzEvh" target="_blank"><img src={Discord} alt="Discord"/></a></li>
                <li><a href="https://twitter.com/rockintuna" target="_blank"><img src={Twitter} alt="Twitter"/></a></li>
                <li><a href="https://www.instagram.com/rockintunanft/" target="_blank"><img src={Instagram} alt="Instagram"/></a></li>
                <li><a href="https://opensea.io/collection/rockintuna" target="_blank"><img src={OpenSea} alt="OpenSea"/></a></li>
            </ul>
        </footer>
        <div className="copyright">
            <p>©2021 Rockin Tuna by Greenslant. All rights reserved.</p>
        </div>
    </div>
        </>
    )
}
