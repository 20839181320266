import React from 'react'
import '../Style/style.css'
import WaveSeperator3 from '../Assets/rockintuna-separator3.png'
import Teambackground from '../Assets/rt-team-bg.jpg'
import TeambackgroundMob from '../Assets/RockinTuna-TeamBG-m-2x.jpg'
import Tony from '../Assets/Team/rt-team-1-tony.png'
import Matt from '../Assets/Team/rt-team-2-matt.png'
import Anthony from '../Assets/Team/rt-team-3-anthony.png'
import Paul from '../Assets/Team/rt-team-4-paul.png'

export default function Team() {
    return (
       <>
        <section className="artist" id="team">
			<div className="separator">
				<img src={WaveSeperator3} alt="WaveSeperator3" />
			</div>
			<div className="artist-bg">
				<img src={Teambackground} alt="Teambackground" className="bg-header-dsk" />
				<img
					src={TeambackgroundMob}
					alt="TeambackgroundMob"
					className="bg-header-mob"
				/>
				<div className="team-container">
					<div className="team-content-wrapper">
						<div className="container-small">
							<div className="team-content">
								<h1>Team</h1>
								<p>
								Rockin Tuna was founded by business partners that have a passion for art, marketing, blockchain, 
								music and fishing. The Rockin Tuna team is committed to making the project a wild success.
								</p>
							</div>
							<div className="builders">
								<div className="builder">
									<div className="builder-img">
										<img src={Matt} alt="Matt" />
									</div>
									<div className="builder-info">
										<h3>Matt</h3>
										<p>// PROJECT LEAD</p>
										<p>// DEVELOPER</p>
									</div>
								</div>
								<div className="builder">
									<div className="builder-img">
										<img src={Tony} alt="Tony" />
									</div>
									<div className="builder-info">
										<h3>Tony</h3>
										<p>// ARTIST</p>
										<p>// COMMUNITY</p>
									</div>
								</div>
								<div className="builder">
									<div className="builder-img">
										<img src={Anthony} alt="Anthony" />
									</div>
									<div className="builder-info">
										<h3>Anthony</h3>
										<p>// MARKETING </p>
										<p>// COMMUNITY</p>
									</div>
								</div>
								<div className="builder">
									<div className="builder-img">
										<img src={Paul} alt="Paul" />
									</div>
									<div className="builder-info">
										<h3>Paul</h3>
										<p>// GRAPHIC DESIGN </p>
										<p>// UI/UX</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
       </>
    )
}
