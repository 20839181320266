import React from 'react'
import '../Style/style.css'

export default function About() {
    return (
        <>
        <section className="about-rockin-tuna" id="about">
			<div className="container border-btm">
				<div className="about-content-wrapper">
					<h1>About Rockin Tuna</h1>
					<p>
						Rockin Tuna is a collection of absolutely rockin NFTs that were
						created with passion, every feature and trait hand-drawn from the
						inspiration of world-renowned artist Tony Ciavarro. Each one
						expresses a different genre and is unique in appearance. Rockin Tuna
						holders can participate in exclusive events such as NFT claims,
						raffles, community giveaways, and more..
					</p>
	    
	    				<p>
						A portion of all proceeds go to #TeamSeas benefiting Ocean Conservancy and Ocean Cleanup.
					</p>
					<div className="hero-buttons">
						<a href="https://opensea.io/collection/rockintuna" target="_blank">Buy in OpenSea</a>
					</div>
				</div>
			</div>
		</section>
        </>
    )
}
